* {
    padding: 0;
    margin: 0;
}

html,
body {
    overflow: hidden;
}

body {
    display: flex;
    width: 100vw;
    height: 100vh;;
}

.canvas {
    flex: 1;
    width: 60vw;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.overlay {
    width: 40vw;
    min-height: 100vh;
    padding: 1rem;
    background-color: #fff;
    overflow-y: scroll;
}

.body {
    padding: 15px 0;
}

.content {
    display: none;
}

.content.active {
    display: block;
}

p + p{
    margin-top: 15px;
}

.link {
    font-weight: bold;
    color: red;
    text-decoration: underline;
}